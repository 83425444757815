<template>
  <div v-if="showConfig">
    <NavItem
      :icon="expand ? 'mdi-chevron-up' : 'mdi-chevron-down'"
      @click.native="handleExpand"
    >
      Configuração
    </NavItem>

    <v-expand-transition>
      <div v-if="expand">
        <NavItem
          v-if="showConfigUsers"
          isGrouped
          icon="mdi-account"
          route="/controle-de-usuarios"
        >
          Usuários
        </NavItem>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
//import Routes from "../Routes";
import NavItem from "../NavItem";

export default {
  name: "ConfigRoutes",
  components: {
    NavItem
    // Routes
  },
  computed: {
    isPaasEditor() {
      return this.$store.getters.userIsPaasEditor;
    },
    showConfig() {
      return this.$store.getters.getAllowAny([1]);
    },
    showConfigUsers() {
      return this.$store.getters.getAllow(1);
    }
  },
  data() {
    return {
      expand: false
    };
  },
  methods: {
    handleExpand() {
      this.expand = !this.expand;
    }
  }
};
</script>

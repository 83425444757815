import router from "@/router"

export const moduleOidc = {
  state: {
    id_token: "",
    refresh_token: "",
    access_token: "",
    saci_token: "",
    expires_in: 0, 
  },

  actions: {
    // Starts a timer until tokens expire, at which point user must login again. 
    // Once timer ends, user is logged out and tokens are cleared.
    async startTimer({ state, commit }, payload) {
      // Set initial expires_in value
      if (payload !== undefined) // if no payload is sent, skip this
        state.expires_in = payload.expires_in;
      
      // Variables related to alerting user once time is almost up
      let alerted = false; // True if user was already alerted
      const seconds_to_alert = 30; // How many seconds before time is up when user is alerted
      
      // Main loop
      while (state.expires_in > 0) { // While there's time remaining
        await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second
        // If user is logged in
        if (state.access_token != "") {
          commit('tickTimer'); // Decrese timer by 1
          // Alert user once its time
          if (!alerted && state.expires_in <= seconds_to_alert) {
            alerted = true;
            alert(
              `Sua sessão irá expirar em ${state.expires_in} segundos. Por favor, se autentique novamente.`
            );
          }
        }
      }

      // Once loop ends, time is up
      commit('clearTokens'); // Log user out
      commit('clearUserData'); // Clear user data
      if (router.currentRoute.path != "/") { // If not at home already
        router.replace("/"); // Send user to home
      }
    },
  },
   
  mutations: {
    setTokens(state, payload) {
      state.id_token = payload.id_token;
      state.refresh_token = payload.refresh_token;
      state.access_token = payload.access_token;
      state.saci_token = payload.saci_token;
    },

    clearTokens(state) {
      state.id_token = "";
      state.refresh_token = "";
      state.access_token = "";
      state.saci_token = "";
      state.expires_in = 0;
    },

    tickTimer(state) {
      state.expires_in--;
    } 
  },

  getters: {
    isLoggedIn: state => {
      return state.id_token != "";
    },
    getAccessToken: state => {
      if(state.access_token == "")
        return "none"
      ;
      return state.access_token;
    },
    getSACIToken: state => {
      return state.saci_token;
    },
    getExpiresIn: state => {
      return state.expires_in;
    }
  }
};

<template>
  <div>
    <!-- Toggle drawer -->
    <v-app-bar-nav-icon
      class="btn-hamburguer"
      v-if="hiddenDrawer"
      @click.stop="drawer = !drawer"
    />

    <v-navigation-drawer
      v-model="drawer"
      app
      floating
      class="pa-3 move-center"
      color="accent"
    >
      <!-- SACI logo -->
      <v-img
        src="../assets/logo.png"
        contain
        width="50%"
        class="logo"
      />

      <!-- List of routes -->
      <div v-if="componentIsPaas">
        <PaasRoutes />
      </div>
      <div v-if="componentIsPoh">
        <PohRoutes />
      </div>
      <div v-if="componentIsNone">
        <HomeRoutes />
      </div>

      <!-- Bottom of drawer -->
      <template v-slot:append>
        <!-- Remaining time -->
        <p class="mx-5 text-caption">
          Tempo de sessão: {{ remainingTime }}
        </p>
        <!-- FAQ -->
        <v-btn class="mx-1 mb-1 bottom-btn" depressed text to="/faq">
          <v-icon> mdi-help-circle </v-icon>
          <span class="ml-7"> FAQ </span>
        </v-btn>
        <!-- Feedback form link -->
        <v-btn
          class="mx-1 mb-1"
          depressed
          text
          href="https://forms.gle/G1zUrtJriExafCDs8"
          target="_blank"
        >
          <v-icon> mdi-lead-pencil </v-icon>
          <span class="ml-7"> Contato </span>
        </v-btn>
        <!-- Logout button -->
        <div class="mb-6 mx-1">
          <LogoutButton />
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import PaasRoutes from "./PaasRoutes";
import PohRoutes from "./PohRoutes";
import LogoutButton from "./LogoutButton";
import HomeRoutes from "./routes/HomeRoutes";

export default {
  name: "NavDrawer",
  components: {
    PaasRoutes,
    PohRoutes,
    LogoutButton,
    HomeRoutes
  },
  computed: {
    componentIsPaas() {
      return this.$store.getters.isPaas;
    },

    componentIsPoh() {
      return this.$store.getters.isPoh;
    },

    componentIsNone() {
      return this.$store.getters.isNone;
    },

    hiddenDrawer() {
      return this.$vuetify.breakpoint.mdAndDown;
    },

    remainingTime() {
      const sec = this.$store.getters.getExpiresIn;
      // Formats number of seconds to HH:MM
      return new Date(sec * 1000).toISOString().substr(11, 5);
    }
  },
  data() {
    return {
      drawer: null
    };
  }
};
</script>

<style scoped>
.logo {
  margin: 10% 25% 20%;
}

.btn-hamburguer {
  position: absolute;
  margin: 42px 0 0 36px;
  z-index: 2;
}

@media only screen and (min-width: 1980px) {
  .move-center {
    right: auto;
    left: auto;
  }
}
</style>

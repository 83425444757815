<template>
  <table v-if="data && data.length > 0" class="table mt-5">
    <tr class="line">
      <th
        v-for="header in headers"
        :key="header.name"
        :class="['column header text-body-2 font-weight-bold']"
      >
        {{ header.name }}
      </th>
      <th class="column header text-button"></th>
    </tr>

    <template v-for="(line, idx) in data">
      <!-- Linha principal -->
      <tr
        :key="'row-' + idx"
        :class="`line pointer ${idx % 2 == 0 ? 'line-bg' : ''}`"
        @click.stop="handleExpand(idx)"
      >
        <td
          v-for="(header, i) in headers"
          :key="i"
          class="column text-uppercase text-body-2"
        >
          <span v-if="appendIcon && i == 0" class="mx-2">
            <v-icon small>{{ appendIcon }}</v-icon>
          </span>
          {{ line[header.value] }}
        </td>

        <td class="column text-body-2">
          <v-btn class="float-right mr-2" density="compact" text>
            <v-icon>{{
              expand == idx ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </v-btn>
        </td>
      </tr>

      <!-- Linha de expansão (logo após a linha principal) -->
      <tr
        :key="'expand-' + idx"
        :class="`${idx % 2 == 0 ? 'line-bg' : ''}`"
      >
        <td
          :colspan="
            selectable ? headers.length + 2 : headers.length + 1
          "
        >
          <v-expand-transition>
            <div v-if="expand == idx">
              <slot></slot>
            </div>
          </v-expand-transition>
        </td>
      </tr>
    </template>
  </table>
</template>

<script>
export default {
  name: "SimpleTable",
  props: {
    data: Array,
    headers: Array,
    appendIcon: String,
    expandIcon: String,
    expandValue: Number,
    inactive: Boolean,
    noData: String,
    selectable: Boolean,
  },
  data() {
    return {
      expand: -1,
    };
  },
  methods: {
    handleExpand(idx) {
      this.expand =
        this.expand !== idx ? idx : this.expand === -1 ? idx : -1;
      this.$emit("expand", this.expand);
    },
  },
};
</script>

<style scoped>
.alert {
  width: 180px;
}

.alert-content {
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 28px;
  background-color: #e53935;
  color: #fdfdfd;
}

.alert-content span {
  width: 0px;
  display: none;
}

.table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.column {
  padding: 8px;
  color: #757575;
}

.tr-red,
.tr-grey {
  width: 70px;
  text-align: center;
  padding: 2px;
  border-radius: 12px;
}

.nfe {
  overflow-wrap: anywhere;
}

.tr-red {
  background-color: #ef5350;
  color: #e0e0e0;
}

.tr-grey {
  background-color: #e0e0e0;
}

.pointer {
  cursor: pointer;
}

.icon {
  width: 50px;
}

.line-bg {
  background-color: #f5f5f5;
}

.line:hover {
  background-color: #eeeeee;
}

.text-center {
  text-align: center;
}

.header {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}

.center {
  margin: 0 auto;
}

.expand-card {
  border: 1px solid rgba(117, 117, 117, 0.3);
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
}

.label {
  border-radius: 6px;
}

.alert a {
  color: #fdfdfd;
  background-color: #e53935;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  visibility: hidden;
}

.text-red {
  color: red;
}

/* Define a largura fixa para a coluna "Alerta" */
.alert-column {
  width: 280px;
}
</style>

<template>
  <div>
    <Header title="Controle de usuários"></Header>
    <Loading v-if="loading_page.loading" />
    <div v-else class="container">
      <AssistentCard
        class="mb-8"
        :text="`Gerencie o nível de acesso e centro dos usuários. Atualmente o SACI conta com ${users_qtd} usuários!`"
      />
      <div class="search-container">
        <v-text-field
          class="child"
          v-model="search"
          persistent-hint
          hint="Filtre por nome ou e-mail"
          outlined
          dense
        ></v-text-field>
        <v-select
          class="child"
          v-model="select_value"
          :items="items"
          chips
          outlined
          dense
          label=""
          persistent-hint
          hint="Níveis de acesso"
          multiple
        >
          <template v-slot:selection="{ item }">
            <span class="px-3 chip text-caption">{{ item }}</span>
          </template>
        </v-select>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click.stop="handleSearch"
              color="primary"
              dark
              depressed
              v-bind="attrs"
              v-on="on"
            >
              pesquisar
              <v-icon class="ml-2" small>mdi-magnify</v-icon>
            </v-btn>
          </template>
          <span>Filtrar no banco de dados</span>
        </v-tooltip>
      </div>

      <Loading v-if="loading_search.loading" />

      <SimpleTable
        v-else
        :appendIcon="'mdi-account'"
        :data="users"
        :headers="headers"
        @expand="openUserCard"
      >
        <UserCard
          :data="user_data"
          :items="items"
          :centros="centros"
          @save="handleSave"
        />
      </SimpleTable>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import SimpleTable from "@/components/SimpleTable.vue";
import AssistentCard from "@/components/AssistentCard.vue";
import Loading from "@/components/Loading.vue";
import UserCard from "@/handles/userControl/UserCard.vue";
import {
  getInicialData,
  filter,
  updateUser,
} from "@/api/conf/user_control";

export default {
  name: "UserControl",
  components: {
    Header,
    SimpleTable,
    AssistentCard,
    Loading,
    UserCard,
  },
  data() {
    return {
      hello: "hello world",
      niveis: [],
      users: [],
      loading_page: {
        loading: true,
        error: false,
      },
      loading_search: {
        loading: false,
        error: false,
      },
      items: [],
      select_value: [],
      search: "",
      users_qtd: 0,
      user_data: {},
      centros: [],
      headers: [
        {
          name: "Nome",
          value: "userName",
        },
        {
          name: "E-mail",
          value: "email",
        },
      ],
    };
  },
  mounted() {
    getInicialData()
      .then((res) => {
        this.niveis = res.levels;
        this.items = this.niveis.map((e) => e.name);
        this.select_value = [this.items[0]];
        this.users_qtd = res.users_qtd;
        this.users = res.users;
        this.centros = res.centros;
        this.loading_page.loading = false;
      })
      .catch((err) => {
        console.log("error: ", err);
        this.loading_page.loading = false;
        this.loading_page.error = true;
      });
  },
  methods: {
    convertSelectToNumber(_list) {
      let bin = "";

      this.niveis.forEach((nivel) => {
        bin = (_list.includes(nivel.name) ? "1" : "0") + bin;
      });
      return parseInt(bin, 2);
    },
    handleSearch() {
      this.loading_search.loading = true;
      filter(
        this.search,
        this.convertSelectToNumber(this.select_value)
      )
        .then((res) => {
          this.users = res;
          this.loading_search.loading = false;
        })
        .catch((err) => {
          console.log("err", err);
          this.loading_search.loading = false;
        });
    },
    openUserCard(e) {
      if (e === -1) return;

      this.user_data = {
        ...this.users[e],
        idx: e,
        value: this.niveis
          .filter((_) => _.id & this.users[e].level)
          .map((_) => _.name),
      };
    },
    handleSave(_data) {
      const { id, niveis, centro, idx } = _data;
      const level = this.convertSelectToNumber(niveis);

      updateUser(id, centro, level)
        .then((_) => {
          this.users[idx].level = level;
          this.users[idx].centro = centro;
          this.openUserCard(idx);
          if (this.$store.getters.getIdVinculo == id) {
            alert(
              "É necessário sair e realizar o login novamente para efetuar as modificações!"
            );
          }
          console.log(_);
        })
        .catch((err) => {
          console.log("Erro ao atualizar dados do usuário.");
          console.log("error: ", err);
        });
    },
  },
};
</script>

<style scoped>
.container {
  padding: 0 40px;
}

.search-container {
  display: flex;
  gap: 20px;
}

.child {
  flex: 1;
}

.chip {
  background-color: #2196f3;
  margin-right: 5px;
  border-radius: 10px;
  color: white;
}
</style>

import axios from "axios";
import store from "@/store/vuex";

const server_uri = process.env.VUE_APP_SERVER_URI;

//Get report from backend
export const getInicialData = async () => {
    return new Promise((resolve, reject) => {

        const config = {
            headers: { Authorization: `Bearer ${store.getters.getSACIToken}` }, // Obtem access_token
        };

        axios.get(`${server_uri}/conf/usercontrol`, config)
        .then((_res) => {
            resolve(_res.data)
        })
        .catch((err) => {
            console.log('err:', err);
            reject()
        })
    })
}

export const filter = async (filter, level ) => {
    return new Promise((resolve, reject) => {

        const config = {
            headers: { Authorization: `Bearer ${store.getters.getSACIToken}` }, // Obtem access_token
        };

        axios.post(`${server_uri}/conf/usercontrol`, {
            filter,
            level
        }, config)
        .then((_res) => {
            resolve(_res.data)
        })
        .catch((err) => {
            console.log('err:', err);
            reject()
        })
    })
}

export const updateUser = async (id, centro, level ) => {
    return new Promise((resolve, reject) => {

        const config = {
            headers: { Authorization: `Bearer ${store.getters.getSACIToken}` }, // Obtem access_token
        };

        axios.put(`${server_uri}/conf/usercontrol`, {
            id,
            centro,
            level,
        }, config)
        .then((_res) => {
            resolve(_res.data)
        })
        .catch((err) => {
            console.log('err:', err);
            reject()
        })
    })
}

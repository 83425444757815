import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from 'vue-meta'

import store from "@/store/vuex";

import PaasDashboard from "@/views/PaasDashboard.vue";
import PaasSimulador from "@/views/Paas-Simulador.vue";
import PaasHistorico from "@/views/Paas-Historico.vue";
import PaasPublica from "@/views/Paas-Publica.vue";
import PaasPublicar from "@/views/Paas-Publicar.vue"
import PaasRequest from "@/views/Paas-Request.vue";
import PaasRequestHandle from "@/views/PaasRequestHandle";
import LoginEndpoint from "@/views/LoginEndpoint.vue";
import Home from "@/views/Home.vue";
import Faq from "@/views/Faq.vue";
import PohDashboard from "@/views/Poh-Dashboard.vue";
import PohHistorico from "@/views/Poh-Historico.vue";
import PohSimulador from "@/views/Poh-Simulador.vue";
import Admin from "@/views/Admin";
import PrintRooms from "@/views/PrintRooms.vue";
import PaasInfra from "@/views/Paas-Infra.vue";

//config
import UserControl from "@/views/config/UserControl.vue";

Vue.use(VueRouter);
Vue.use(VueMeta);

// Route list
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/faq",
    name: "FAQ",
    component: Faq
  },
  {
    path: "/paas",
    name: "PAAS - Dashboard",
    component: PaasDashboard,
    meta: { requiresAuth: true, paas: true}
  },
  {
    path: "/paas-simulador",
    name: "PAAS - Simulador",
    component: PaasSimulador,
    meta: { requiresAuth: true, paas: true}
  },
  {
    path: "/paas-infra",
    name: "PAAS - Infra",
    component: PaasInfra,
    meta: { requiresAuth: true, paas: true}
  },
  {
    path: "/paas-publicar",
    name: "PAAS - Publicar",
    component: PaasPublicar,
    meta: { requiresAuth: true, paasEditor: true }
  },
  {
    path: "/print-rooms",
    name: "PAAS - Identificação de Salas",
    component: PrintRooms,
    meta: { requiresAuth: true, paasEditor: true }
  },
  {
    path: "/paas-solicitar",
    name: "PAAS - Solicitar",
    component: PaasRequest,
    meta: { requiresAuth: true, paasEditor: true }
  },
  {
    path: "/paas/solicitacao/:hash",
    name: "PAAS - Solicitação de Turmas",
    component: PaasRequestHandle,
  },
  {
    path: "/paas-historico",
    name: "PAAS - Histórico",
    component: PaasHistorico,
    meta: { requiresAuth: true, paas: true}
  },
  {
    path: "/salas/:centro",
    name: "Alocação de Aulas a Salas",
    component: PaasPublica,
  },
  {
    path: "/auth",
    name: "Login Endpoint",
    component: LoginEndpoint
  },
  {
    path: "/poh",
    name: "POH - Dashboard",
    component: PohDashboard,
    meta: { requiresAuth: true, allowedCourse: true }
  },
  {
    path: "/poh-historico",
    name: "POH - Histórico",
    component: PohHistorico,
    meta: { requiresAuth: true, allowedCourse: true }
  },
  {
    path: "/poh-simulador",
    name: "POH - Simulador",
    component: PohSimulador,
    meta: { requiresAuth: true, allowedCourse: true }
  },
  {
    path: "/admin",
    name: 'Admin',
    component: Admin,
    meta: { requiresAuth: true, admin: true },
  },
  {
    path: "/controle-de-usuarios",
    name: 'UserControl',
    component: UserControl,
    meta: { requiresAuth: true, verify_level: true, level: 1 },
  },
];

// Router object
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// Set page titles
// const default_page_title = "SACI";
// router.afterEach(to => {
//   // Use next tick to handle router history correctly
//   // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
//   Vue.nextTick(() => {
//     document.title = to.meta.title || default_page_title;
//   });
// });

// Login navigation guard
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Requires login
    if (store.getters.isLoggedIn) {
      // Is logged in
      next();
    } else {
      // Isn't logged in
      next({ path: "/", replace: true }); // Redirects home
    }
  }if(to.matched.some(record => record.meta.paas)){
    if (store.getters.getAllowPaas) {
      //Paas is allowed
      next();
    } else {
      //Paas not allowed
      next({ path: "/", replace: true }); // Redirects home
    }
  }
  if(to.matched.some(record => record.meta.paasEditor)){
    if (store.getters.userIsPaasEditor) {
      // Is Paas Editor
      next();
    } else {
      // Isn't Paas Editor
      next({ path: "/", replace: true }); // Redirects home
    }
  }if(to.matched.some(record => record.meta.admin)){
    if (store.getters.userIsAdmin) {
      // Is admin
      next();
    } else {
      // Isn't admin
      next({ path: "/", replace: true }); // Redirects home
    }
  }if(to.matched.some(record => record.meta.verify_level)){
    if (store.getters.getAllow(to.meta.level)) {
      // Is allowed
      next();
    } else {
      // Isn't allowed
      next({ path: "/", replace: true }); // Redirects home
    }
  }if(to.matched.some(record => record.meta.allowedCourse)){

    if (store.getters.courseIsAllowed){
      // if course is allowed
      next();
    } else if(store.getters.userIsTester) {
      // if user is tester
      next();
    } else {
      // if course isn't allowed
      next({ path: "/", replace: true }); // Redirects home
    }

  } else {
    // Doesn't require login or paas editor or admin
    next();
  }
});

export default router;

<template>
  <Routes>
    <!-- Routes to student-only modules -->
    <div v-if="userIsStudent">
      <NavItem icon="mdi-school-outline" route="/poh"> POH </NavItem>
    </div>
    <!-- Routes to staff-only modules -->
    <div v-if="userIsStaff">
      <NavItem icon="mdi-door" route="/paas"> PAAS </NavItem>
    </div>

    <div v-if="userIsAdmin">
      <NavItem icon="mdi-cogs" route="/admin"> Admin </NavItem>
    </div>

    <ConfigRoutes />
  </Routes>
</template>

<script>
import Routes from "../Routes";
import NavItem from "../NavItem";
import ConfigRoutes from "./ConfigRoutes.vue";

export default {
  name: "HomeRoutes",
  components: {
    NavItem,
    Routes,
    ConfigRoutes
  },
  computed: {
    userIsStudent() {
      return this.$store.getters.userIsStudent;
    },
    userIsStaff() {
      return this.$store.getters.userIsStaff;
    },
    userIsAdmin() {
      return this.$store.getters.userIsAdmin;
    }
  }
};
</script>

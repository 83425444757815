<template>
  <div class="pa-4">
    <div class="parent">
      <v-select
        class="child"
        v-model="niveis"
        :items="items"
        chips
        dense
        outlined
        label="Níveis de acesso"
        multiple
        :disabled="disabled"
      >
        <template v-slot:selection="{ item }">
          <span class="px-3 chip text-caption">{{ item }}</span>
        </template>
      </v-select>
      <v-select
        dense
        class="child"
        v-model="centro"
        :items="centros"
        outlined
        label="Centro"
        :disabled="disabled"
      ></v-select>
    </div>
    <v-btn
      @click.stop="handleSave"
      color="primary"
      :disabled="disabled || !mudou"
    >
      Salvar
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "UserCard",
  props: {
    data: Object,
    items: Array,
    centros: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      centro: "",
      niveis: [],
    };
  },
  mounted() {
    this.centro = JSON.parse(JSON.stringify(this.data.centro));
    this.niveis = JSON.parse(JSON.stringify(this.data.value));
  },
  computed: {
    mudou() {
      return (
        this.data.centro !== this.centro ||
        this.niveis.toString() !== this.data.value.toString()
      );
    },
  },
  methods: {
    handleSave() {
      this.$emit("save", {
        id: this.data.idVinculo,
        niveis: this.niveis,
        centro: this.centro,
        idx: this.data.idx,
      });
    },
  },
};
</script>

<style scoped>
.parent {
  display: flex;
  gap: 20px;
}

.child {
  flex: 1;
}

.chip {
  background-color: #2196f3;
  margin-right: 5px;
  border-radius: 10px;
  color: white;
}
</style>
